/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    asyncDebounce,
    IFormState,
    useSnackbar,
    IValidationError,
    ValidationErrorType,
    IFormValidationError,
    OnlinePatientManagementContext,
    useAuthenticatedUser
} from '@ngt/opms';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import * as Dtos from '../api/dtos';
import { DateTime } from 'luxon';

/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/

export interface IUseConsentFormOptions {
    consentForm?: Dtos.Consent;
    institution?: Dtos.Institution;
    onCancel?: () => void;
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useConsentForm = <TForm extends Dtos.ReviewConsent>({
    consentForm,
    institution,
    onCancel
}: IUseConsentFormOptions) => {
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const [user] = useAuthenticatedUser();

    const formInitial = React.useMemo(() => {
        return {
            ...consentForm,
            consentFormId: consentForm?.id,
            reviewedBy: !!consentForm?.reviewedBy && consentForm?.consentStatus !== Dtos.ConsentStatusType.ReadyForReview ?
                consentForm.reviewedBy :
                user?.displayName,
            reviewedDate: !!consentForm?.reviewedDate && consentForm?.consentStatus !== Dtos.ConsentStatusType.ReadyForReview ?
                DateTime.fromISO(consentForm.reviewedDate) :
                DateTime.local().toISO({ includeOffset: false })
        } as Dtos.ReviewConsent;
    }, [user, consentForm]);

    const consentFormId = React.useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === 3)?.id;
    }, [consentForm]);

    const optionalConsentFormId = React.useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === 5)?.id;
    }, [consentForm]);

    const servicesAustraliaConsentFormId = React.useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === 6)?.id;
    }, [consentForm]);

    const hasUserSubmittedAustralianConsent = React.useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === 6)?.id != null;
    }, [consentForm]);

    const hasUserSubmittedOptionalConsent = React.useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === 5)?.id != null;
    }, [consentForm]);

    const hasUserSubmittedMoreOptionalConsent = React.useMemo(() => {
        return consentForm?.sourceDocuments?.filter(x => x.fileTypeId === 5)[1]?.id != null;
    }, [consentForm]);

    const hasUserSubmittedMainConsent = React.useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === 3)?.id != null;
    }, [consentForm]);

    // override the allow submit function so that submit is only blocked when there is a critical error.
    const allowSubmit = React.useCallback(async ({ errors }: IFormState<TForm, IValidationError>) => {
        if (!errors) {
            return true;
        }

        return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => e.type != null && e.type>= ValidationErrorType.Normal));
    }, []);

    const onValidate = React.useCallback(async (formState: IFormState<TForm, IValidationError>) => {

        let errors: Record<string, IFormValidationError[]> = {};

        const deletionSelectionRequired = () => {
            if (formState.values.mainConsentCurrentTMDVersion === Dtos.LookupYesNoType.No ||
                formState.values.optionalConsentCurrentTMDVersion === Dtos.LookupYesNoType.No ||
                formState.values.optionalConsentCurrentSecondTMDVersion === Dtos.LookupYesNoType.No ||
                formState.values.servicesAustraliaConsentCurrentTMDVersion === Dtos.LookupYesNoType.No ||

                formState.values.currentPicfsApproved === Dtos.LookupYesNoType.No ||
                formState.values.patientSignedPicfs === Dtos.LookupYesNoType.No ||
                formState.values.allPagesPresent === Dtos.LookupYesNoType.No ||
                formState.values.investigatorSigned === Dtos.LookupYesNoType.No ||
                formState.values.saConsentFullyChecked === Dtos.LookupYesNoType.No ||
                formState.values.personWhoSignedAuth === Dtos.LookupYesNoType.No) {
                return false;
            }
            else {
                return true;
            }
        }

        if (formState.values.saveButtonClicked == true) { // skip validation

            return errors;
        }

        //Table
        //Main Consent
        //main consent only needs to be checked if the participant hasn't used econsent
        if (formState.values.participantUsedEConsent === false) {
            if (!formState.values.consentFormVersion) {
                errors['consentFormVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-001',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }

            if (!formState.values.mainConsentDate) {
                errors['mainConsentDate'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-002',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
            else {
                if (DateTime.fromISO(formState.values.mainConsentDate).startOf("day") > DateTime.local().startOf("day")) {
                    errors['mainConsentDate'] = [{
                        message: 'This field must not be a future date.',
                        detailedMessage: 'This field must not be a future date.',
                        code: 'R-003',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }

                if (institution?.activatedDate && (DateTime.fromISO(formState.values.mainConsentDate).startOf("day") < DateTime.fromISO(institution.activatedDate!).startOf("day"))) {
                    errors['mainConsentDate'] = [{
                        message: 'This field must not be prior to site activation date.',
                        detailedMessage: 'This field must not be prior to site activation date.',
                        code: 'R-004',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }
            }

            if (formState.values.mainConsentCurrentTMDVersion == null) {
                errors['mainConsentCurrentTMDVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-005',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }
        }

        // optional
        if (hasUserSubmittedOptionalConsent) {
            if (!formState.values.optionalConsentVersion) {
                errors['optionalConsentVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-006',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }


            if (!formState.values.optionalConsentDate) {
                errors['optionalConsentDate'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-007',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
            else {
                if (DateTime.fromISO(formState.values.optionalConsentDate).startOf("day") > DateTime.local().startOf("day")) {
                    errors['optionalConsentDate'] = [{
                        message: 'This field must not be a future date.',
                        detailedMessage: 'This field must not be a future date.',
                        code: 'R-008',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }

                if (institution?.activatedDate && (DateTime.fromISO(formState.values.optionalConsentDate).startOf("day") < DateTime.fromISO(institution.activatedDate!).startOf("day"))) {
                    errors['optionalConsentDate'] = [{
                        message: 'This field must not be prior to site activation date.',
                        detailedMessage: 'This field must not be prior to site activation date.',
                        code: 'R-009',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }
            }

            if (formState.values.optionalConsentCurrentTMDVersion == null) {
                errors['optionalConsentCurrentTMDVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-010',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }
        }


        // Services Australia
        if (hasUserSubmittedAustralianConsent) {
            if (!formState.values.servicesAustraliaConsentVersion) {
                errors['servicesAustraliaConsentVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-011',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }


            if (!formState.values.servicesAustraliaConsentDate) {
                errors['servicesAustraliaConsentDate'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-012',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
            else {
                if (DateTime.fromISO(formState.values.servicesAustraliaConsentDate).startOf("day") > DateTime.local().startOf("day")) {
                    errors['servicesAustraliaConsentDate'] = [{
                        message: 'This field must not be a future date.',
                        detailedMessage: 'This field must not be a future date.',
                        code: 'R-013',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }

                if (institution?.activatedDate && (DateTime.fromISO(formState.values.servicesAustraliaConsentDate).startOf("day") < DateTime.fromISO(institution.activatedDate!).startOf("day"))) {
                    errors['servicesAustraliaConsentDate'] = [{
                        message: 'This field must not be prior to site activation date.',
                        detailedMessage: 'This field must not be prior to site activation date.',
                        code: 'R-014',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }
            }

            if (formState.values.servicesAustraliaConsentCurrentTMDVersion == null) {
                errors['servicesAustraliaConsentCurrentTMDVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-015',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }
        }
        // end of form

        if (formState.values.currentPicfsApproved == null) {
            errors['currentPicfsApproved'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-016',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        //if (formState.values.currentPicfsApproved === 0 && !formState.values.commentCurrentPicfsApproved) {
        //    errors['commentCurrentPicfsApproved'] = [{
        //        message: 'This field is required.',
        //        detailedMessage: 'This field is required.',
        //        code: 'R-017',
        //        type: Dtos.ValidationErrorType.Normal
        //    }] as IFormValidationError[];
        //}

        if (formState.values.patientSignedPicfs == null) {
            errors['patientSignedPicfs'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-018',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (formState.values.allPagesPresent == null) {
            errors['allPagesPresent'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-019',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (formState.values.investigatorSigned == null) {
            errors['investigatorSigned'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-020',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (formState.values.saConsentFullyChecked == null && hasUserSubmittedAustralianConsent) {
            errors['saConsentFullyChecked'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-021',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (formState.values.personWhoSignedAuth == null) {
            errors['personWhoSignedAuth'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-022',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (!formState.values.nameOfPersonWhoSigned) {
            errors['nameOfPersonWhoSigned'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-023',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (!formState.values.comments) {
            errors['comments'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-024',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (!formState.values.reviewedBy) {
            errors['reviewedBy'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-025',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        if (!formState.values.reviewedDate) {
            errors['reviewedDate'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-026',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }
        else {
            if (DateTime.fromISO(formState.values.reviewedDate).startOf("day") > DateTime.local().startOf("day")) {
                errors['reviewedDate'] = [{
                    message: 'This field must not be a future date.',
                    detailedMessage: 'This field must not be a future date.',
                    code: 'R-027',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }

            if (institution?.activatedDate && (DateTime.fromISO(formState.values.reviewedDate).startOf("day") < DateTime.fromISO(institution.activatedDate!).startOf("day"))) {
                errors['reviewedDate'] = [{
                    message: 'This field must not be prior to site activation date.',
                    detailedMessage: 'This field must not be prior to site activation date.',
                    code: 'R-028',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
        }

        if (hasUserSubmittedMainConsent) {
            if (formState.values.deleteMainConsent == null && !deletionSelectionRequired()) {
                errors['deleteMainConsent'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-029',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
        }

        if (hasUserSubmittedOptionalConsent) {
            if (formState.values.deleteOptionalConsent == null && !deletionSelectionRequired()) {
                errors['deleteOptionalConsent'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-030',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
        }

        if (hasUserSubmittedAustralianConsent) {
            if (formState.values.deleteSAConsent == null && !deletionSelectionRequired()) {
                errors['deleteSAConsent'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-031',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
        }

        if (formState.values.deleteMainConsent !== Dtos.LookupYesNoType.Yes &&
            formState.values.deleteOptionalConsent !== Dtos.LookupYesNoType.Yes &&
            formState.values.deleteOptionalSecondConsent !== Dtos.LookupYesNoType.Yes &&
            formState.values.deleteSAConsent !== Dtos.LookupYesNoType.Yes &&
            !deletionSelectionRequired()) {
            errors['deleteMainConsent'] = [{
                message: 'Please input yes for the problem consent form.',
                detailedMessage: 'Please input yes for the problem consent form.',
                code: 'R-032',
                type: Dtos.ValidationErrorType.Normal
            }] as IFormValidationError[];
        }

        // optional 2
        if (hasUserSubmittedMoreOptionalConsent) {
            if (!formState.values.optionalConsentSecondVersion) {
                errors['optionalConsentSecondVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-033',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }


            if (!formState.values.optionalConsentSecondDate) {
                errors['optionalConsenSecondtDate'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-034',
                    type: Dtos.ValidationErrorType.Normal
                }] as IFormValidationError[];
            }
            else {
                if (DateTime.fromISO(formState.values.optionalConsentSecondDate).startOf("day") > DateTime.local().startOf("day")) {
                    errors['optionalConsentSecondDate'] = [{
                        message: 'This field must not be a future date.',
                        detailedMessage: 'This field must not be a future date.',
                        code: 'R-035',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }

                if (institution?.activatedDate && (DateTime.fromISO(formState.values.optionalConsentSecondDate).startOf("day") < DateTime.fromISO(institution.activatedDate!).startOf("day"))) {
                    errors['optionalConsentSecondDate'] = [{
                        message: 'This field must not be prior to site activation date.',
                        detailedMessage: 'This field must not be prior to site activation date.',
                        code: 'R-036',
                        type: Dtos.ValidationErrorType.Normal
                    }] as IFormValidationError[];
                }
            }

            if (formState.values.optionalConsentCurrentSecondTMDVersion == null) {
                errors['optionalConsentCurrentSecondTMDVersion'] = [{
                    message: 'This field is required.',
                    detailedMessage: 'This field is required.',
                    code: 'R-037',
                    type: Dtos.ValidationErrorType.Normal,
                }] as IFormValidationError[];
            }
        }

        console.log(errors);

        return errors;

    }, [institution, hasUserSubmittedAustralianConsent, hasUserSubmittedMainConsent, hasUserSubmittedOptionalConsent, hasUserSubmittedMoreOptionalConsent]);

    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors, values }: IFormState<TForm, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Error
                    </AlertTitle>
                    An error occurred while attempting to review the consent.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type != null && e.type >= ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Consent Not Reviewed
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and try again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async ({ values }: IFormState<TForm, IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Error
                </AlertTitle>
                An error occurred while attempting to review the consent.
            </>,
            { variant: 'critical' }
        );
    }, [enqueueSnackbar]);

    const handleSubmit = React.useCallback(async ({ values }: IFormState<TForm, IValidationError>) => {
       
        onlinePatientManagement.serviceStackClient
            .post(new Dtos.ReviewConsent({ 
                ...values,
                deleteSAConsent: false
            }))
            .then(() => {
                //close dialog
                if (onCancel) {
                    onCancel();
                }

                history.push('/');

                //patientsActions.loadByCodes(null, null, null, institutionCode, patientStateIds)();

                if (values.saveButtonClicked != true)
                {

                    if (values.currentPicfsApproved === Dtos.LookupYesNoType.No ||
                        values.patientSignedPicfs === Dtos.LookupYesNoType.No ||
                        values.allPagesPresent === Dtos.LookupYesNoType.No ||
                        values.investigatorSigned === Dtos.LookupYesNoType.No ||
                        values.saConsentFullyChecked === Dtos.LookupYesNoType.No ||
                        values.personWhoSignedAuth === Dtos.LookupYesNoType.No)
                    {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    Consent Form Reviewed
                                </AlertTitle>
                                New consent form required.
                            </>,
                            { variant: 'success' }
                        );
                    }
                    else
                    {
                        enqueueSnackbar(
                            <>
                                <AlertTitle>
                                    Consent Form Reviewed
                                </AlertTitle>
                                The consent form was successfully confirmed and deleted.
                            </>,
                            { variant: 'success' }
                        );
                    

                    }
                }
                else
                {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Form Saved
                            </AlertTitle>
                            The consent form was successfully saved.
                        </>,
                        { variant: 'success' }
                    );

                }
            })
            .catch(() => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while attempting to review the consent.
                    </>,
                    { variant: 'critical' }
                );
            });
    }, [history, onCancel, onlinePatientManagement.serviceStackClient, enqueueSnackbar])

    const handleDifferentSubmit = React.useCallback(async ({ values }: IFormState<TForm, IValidationError>) => {

        onlinePatientManagement.serviceStackClient
            .post(new Dtos.ReviewConsent(values))
            .then(() => {
                //close dialog
                if (onCancel) {
                    onCancel();
                }

                history.push('/');

                //patientsActions.loadByCodes(null, null, null, institutionCode, patientStateIds)();

                if (values.currentPicfsApproved === Dtos.LookupYesNoType.No ||
                    values.patientSignedPicfs === Dtos.LookupYesNoType.No ||
                    values.allPagesPresent === Dtos.LookupYesNoType.No ||
                    values.investigatorSigned === Dtos.LookupYesNoType.No ||
                    values.saConsentFullyChecked === Dtos.LookupYesNoType.No ||
                    values.personWhoSignedAuth === Dtos.LookupYesNoType.No) {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Form Reviewed
                            </AlertTitle>
                            New consent form required.
                        </>,
                        { variant: 'success' }
                    );
                }
                else {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Consent Form Reviewed
                            </AlertTitle>
                            The consent form was successfully confirmed and deleted.
                        </>,
                        { variant: 'success' }
                    );
                }

            })
            .catch(() => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while attempting to review the consent.
                    </>,
                    { variant: 'critical' }
                );
            });
    }, [history, onCancel, onlinePatientManagement.serviceStackClient, enqueueSnackbar])

    return {
        formInitial,
        handleSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate: debouncedValidate,
        allowSubmit,
        consentFormId,
        optionalConsentFormId,
        servicesAustraliaConsentFormId,
        hasUserSubmittedMainConsent,
        hasUserSubmittedOptionalConsent,
        hasUserSubmittedMoreOptionalConsent,
        hasUserSubmittedAustralianConsent,
        handleDifferentSubmit
    }
}

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useConsentForm;

