
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, makeStyles, Typography } from "@material-ui/core";
import { Form, InstitutionsContext, OnlinePatientManagementContext, useLookupsByFormDefinitionId } from "@ngt/opms";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";

import { DocumentPdfViewer } from "src/containers/PdfViewer";
import useSaConsentForm from "src/hooks/useSaConsentForm";
import * as Dtos from '../../api/dtos';


const SA_CONSENT_FILE_TYPE = 6;

export type OnApprove = ((patient: Dtos.Patient, consentForm: Dtos.Consent, documentId: number) => Promise<void>) | ((patient: Dtos.Patient, consentForm: Dtos.Consent, documentId: number) => void);
export type OnReject = OnApprove;

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        color: theme.palette.secondary.main,
        padding: theme.spacing(2.5, 3, 0),
        fontSize: '1.25rem !important',
        fontWeight: 500,
    },
    dialogBody: {
        padding: theme.spacing(2.5, 3, 0),
    },
    dialogContent: {
        padding: 0
    },
    dialogActions: {
        padding: `${theme.spacing(1, 0, 0)}`
    },
    historyDropDown: {
        padding: theme.spacing(2.5, 3, 0),
    },
}));


interface ISaConsentReviewDialogProps {
    open: boolean;
    formDefinitionId: number;
    onCancel: () => void;
    onReject: OnReject;
    onApprove: OnApprove;
    consentForm?: Dtos.Consent;
    patient?: Dtos.Patient;
}


const SaConsentReviewDialog: FC<ISaConsentReviewDialogProps> = ({
    open,
    formDefinitionId,
    onCancel,
    onReject,
    onApprove,
    patient,
    consentForm
}) => {
    const classes = useStyles();
    
    const { institutions } = useContext(InstitutionsContext);
    
    const institution = useMemo(() => {
        return institutions?.find(i => i.id === patient?.institutionId);
    }, [institutions, patient]);
    
    const [rejectConfirmOpen, setRejectConfirmOpen] = useState<boolean>(false);
    const [approveConfirmOpen, setApproveConfirmOpen] = useState<boolean>(false);

    const [ lookups ] = useLookupsByFormDefinitionId(formDefinitionId, true);

    const {
        formInitial,
        allowSubmit
    } = useSaConsentForm({ 
        patient, 
        institution,
        consentForm
    });

    const sourceDocument: Dtos.SourceDocument | undefined = useMemo(() => {
        return consentForm?.sourceDocuments?.find(x => x.fileTypeId === SA_CONSENT_FILE_TYPE);
    }, [consentForm?.sourceDocuments]);

    const handleApprove = useCallback(() => {
        try {
            if (patient && consentForm && sourceDocument?.id) {
                onApprove(patient, consentForm, sourceDocument.id)
            }
        } finally {
            setApproveConfirmOpen(false);
        }
    }, [consentForm, onApprove, patient, setApproveConfirmOpen]);

    const handleReject = useCallback(() => {
        try {
            if (patient && consentForm && sourceDocument?.id) {
                onReject(patient, consentForm, sourceDocument.id);
            }
        } finally {
            setRejectConfirmOpen(false);
        }
    }, [consentForm, onReject, patient, setRejectConfirmOpen]);

    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    return (
        <>
            <Dialog 
                fullWidth
                disableBackdropClick
                open={open}
                scroll="body"
                maxWidth="lg"
                onClose={onCancel}
            >
                <Form
                    initialValues={formInitial}
                    validateOn="onChange"
                    allowSubmit={allowSubmit}
                    fieldErrors={'default'}
                    lookups={lookups ?? undefined}
                >
                    <DialogContent className={classes.dialogContent}>
                        <Typography
                            variant="h4"
                            color="primary"
                            className={classes.dialogTitle}
                            style={{ marginBottom: '16px' }}
                        >
                            Confirm and process consent form for patient {patient?.studyNumber}
                        </Typography>
                        {
                            sourceDocument?.file?.id ?
                                <DocumentPdfViewer
                                    documentId={sourceDocument.file.id}
                                /> :
                                <CircularProgress style={{ display: 'block', margin: '0 auto' }}/>
                        }
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button 
                            onClick={handleCancel} 
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => setRejectConfirmOpen(true)}
                        >
                            Reject
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => setApproveConfirmOpen(true)}
                        >
                            Confirm and Process
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>

            <Dialog 
                fullWidth
                disableBackdropClick
                open={rejectConfirmOpen}
                scroll="body"
                maxWidth="sm"
            >
                <DialogContent className={classes.dialogContent}>
                    <Typography
                        variant="h4"
                        color="primary"
                        className={classes.dialogTitle}
                        style={{ marginBottom: '16px' }}
                    >
                        Confirm rejection for this SA consent upload?
                    </Typography>
                    <Typography
                        variant="body1"
                        color="primary"
                        className={classes.dialogBody}
                        style={{ marginBottom: '16px' }}
                    >
                        Rejecting this Service Australia consent document will result in it's deletion, 
                        followed by the requirement of this document to be re-uploaded for this participant. 
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setRejectConfirmOpen(false)} 
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleReject}
                    >
                        Confirm Rejection
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                fullWidth
                disableBackdropClick
                open={approveConfirmOpen}
                scroll="body"
                maxWidth="sm"
            >
                <DialogContent className={classes.dialogContent}>
                    <Typography
                        variant="h4"
                        color="primary"
                        className={classes.dialogTitle}
                        style={{ marginBottom: '16px' }}
                    >
                        Confirm approval for this SA consent upload?
                    </Typography>
                    <Typography
                        variant="body1"
                        color="primary"
                        className={classes.dialogBody}
                        style={{ marginBottom: '16px' }}
                    >
                        Approving this Service Australia consent document will specify this document is ready for exporting.
                        Once set, the Service Australia consent document will no longer be available to edit
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setApproveConfirmOpen(false)} 
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleApprove}
                    >
                        Confirm Approval
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


const SaConsentReviewDialogWrapper: FC<Omit<ISaConsentReviewDialogProps, "formDefinitionId">> = (props) => {
    const { formMetadata } = useContext(OnlinePatientManagementContext);

    const formDefinition = useMemo(() => {
        return formMetadata?.find(x => x.formDefinitionCode === 'consent');
    }, [formMetadata]);

    if (!formDefinition?.formDefinitionId) {
        return null;
    }

    return (
        <SaConsentReviewDialog 
            {...props}
            formDefinitionId={formDefinition.formDefinitionId}
        />
    );
}


export default SaConsentReviewDialogWrapper;