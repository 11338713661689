/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the invitation page component
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

import { useParams } from 'react-router';

import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Accordion, AccordionDetails, AccordionProps, AccordionSummary, AccordionSummaryProps, AppBar, Button, CardMedia, Checkbox, FormControlLabel, FormGroup, Grid, Link, List,
    ListItem, ListItemIcon, ListItemText, makeStyles, styled, TextField, Theme,
    Tooltip, Typography
} from '@material-ui/core';
import { OnlinePatientManagementContext, useIsMobile, useSnackbar } from '@ngt/opms';
import { useForm } from "react-hook-form";

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';

import AlertTitle from '@material-ui/lab/AlertTitle';
import { DateTime } from 'luxon';
import { StatusTypeEnum } from '../../api/dtos';
import studyProceduresImage from '../../assets/img/optima_study_procedures.jpg';
import randomisationImage from '../../assets/img/randomisation.png';
import trialPlanImage from '../../assets/img/trial_plan.png';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInvitationPageProps {
}

interface IInvitationPageParams {
    guid: string
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    title: {
        color: theme.palette.secondary.main,
        marginBottom: '1rem'
    },
    subtitle: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
        marginTop: '1.5rem',
        marginBottom: '1rem',
        fontWeight: 'bold'
    },
    eoiTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.75rem',
        marginTop: '1.5rem',
        marginBottom: '1rem',
        fontWeight: 'bold'
    },
    p: {
        marginBottom: '1rem'
    },
    cardMedia: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%'
    },
    cardMediaContainer: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative'
    },
    quickAccess: {
        display: 'flex',
        backgroundColor: 'white'
    },
    quickAccessHeader: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        padding: '1rem',
        fontSize: '1.25rem'
    },
    quickAccessBody: {
        display: 'flex',
        backgroundColor: 'white',
        color: theme.palette.secondary.main,
        fontSize: '1.0rem'
    },
    quickAccessSummary: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        fontSize: '1.25rem'
    },
    link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
    },
    accordions: {
        marginTop: '1rem'
    },
    accordionSummary: {
        color: theme.palette.primary.main,
        fontSize: '2rem'
    },
    listImage: {
        width: '100%',
        height: 'auto'
    },
    eoiGridItem: {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'center'

    },
    eoiGrid: {
        '& .MuiGrid-item': {
            padding: theme.spacing(0, 2)
        }
    },
    eoiSubmit: {
        borderRadius: 15,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },
    error: {
        color: '#f44336'
    },
    submitSuccess: {
        backgroundColor: "#ece7f1",
        color: theme.palette.primary.main,
        margin: theme.spacing(3, 0),
        padding: theme.spacing(5),
    },
    submitSuccessMessage: {
        fontSize: '2rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    submitSuccessIcon: {
        color: theme.palette.secondary.main,
        fontSize: '4rem'
    },
    errorMessage: {
        color: 'red',
        padding: '0.15rem',
        fontSize: '0.85rem'
    },
    listItem: {
        alignItems: 'flex-start'
    },
    listItemIcon: {
        marginTop: '10px'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const CustomAccordion = styled((props: AccordionProps) => (
    <Accordion elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    }
}));

const CustomToolTip: React.FunctionComponent<{
    label?: string,
    title?: string
}> = ({
    label,
    title
}) => {
    const tip: React.ReactElement = (
        <span style={{ cursor: "help", textDecoration: "underline", textDecorationStyle: "dotted" }}>
            {label}
            <sup style={{ fontSize: 10 }}>&#9432;</sup>
        </span >
    );

    return (
        <Tooltip title={title!} children={tip}/>
    );
}

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronRight} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '&  .MuiAccordionSummary-expandIcon': {
        color: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiTypography-body1': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem'
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const EoiField: React.FunctionComponent<{
    label?: string,
    inputComponent: React.ReactNode
}> = ({
    label,
    inputComponent
}) => {
    const classes = useStyles();

    return (
        <>
            {
                !!label && <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={2}
                    xl={2}
                    className={classes.eoiGridItem}
                >
                    {label}
                </Grid>
            }
            <Grid
                item
                xs={12}
                sm={12}
                md={!!label ? 8 : 12}
                lg={!!label ? 10 : 12}
                xl={!!label ? 10 : 12}
                className={classes.eoiGridItem}
            >
                {inputComponent}
            </Grid>
        </>    
    );
    }

const ErrorMessage: React.FunctionComponent<{ message: string }> = ({ message }) => {
    const classes = useStyles();

    return (
        <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faExclamationCircle} />
            <span style={{ marginLeft: "0.5rem" }}>{message}</span>
        </div>
    );
}

const EoiForm: React.FunctionComponent<{
    setEoiSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
    setEoiSubmitted
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const params = useParams<IInvitationPageParams>();

    const invitationGuid = params?.guid;

    const onSubmit = React.useCallback((data) => {

        const eoi: Dtos.Eoi = new Dtos.Eoi({
            firstName: data.firstName,
            lastName: data.lastName,
            confirmed: data.confirmed,
            dateSubmitted: DateTime.local().toUTC().toString(),
            statusId: StatusTypeEnum.Waiting
        });
        
        client
            .post(new Dtos.EoiPostSave({
                eoi: eoi,
                invitationGuid: invitationGuid
            }))
            .then(response => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Expression of Interest Submitted
                        </AlertTitle>
                        Your expression of interest was submitted successfully.
                    </>,
                    { variant: 'success' }
                );
                setEoiSubmitted(true);
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Expression of Interest Not Submitted
                        </AlertTitle>
                        {e.responseStatus.message}
                    </>,
                    { variant: 'critical' }
                );
            })
    }, [client, enqueueSnackbar, invitationGuid, setEoiSubmitted]);

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<Dtos.Eoi>();

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={2}
                    className={classes.eoiGrid}
                >
                    <EoiField
                        label={"First Name"}
                        inputComponent={
                            <>
                                <TextField
                                    label="First name"
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    fullWidth
                                    {...register("firstName", { required: true })}
                                />
                                {errors.firstName && <ErrorMessage message={"This field is required"}/>}
                            </>
                        }
                    />
                    <EoiField
                        label={"Last Name"}
                        inputComponent={
                            <>
                                <TextField
                                    label="Last name"
                                    variant="outlined"
                                    color="primary"
                                    margin="dense"
                                    fullWidth
                                    {...register("lastName", { required: true })}
                                />
                                {errors.lastName && <ErrorMessage message={"This field is required"} />}
                            </>
                        }
                    />
                    <EoiField
                        inputComponent={
                            <>
                                <FormGroup style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
                                    <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...register("confirmed", { required: true })}
                                            />
                                        }
                                        label="I confirm that I have read and understood the information on this page"
                                    />
                                        {errors.confirmed && <ErrorMessage message={"This field is required"} />}
                                    </div>
                                </FormGroup>
                            </>
                        }
                    />
                </Grid>
                
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.eoiSubmit}
                    disabled={isSubmitting}
                    fullWidth
                >
                    Submit
                </Button>
            </form>
        </>    
    );
}

const InvitationPage: React.FunctionComponent<IInvitationPageProps> = () => {
    const classes = useStyles();

    const isMobile = useIsMobile();

    const params = useParams<IInvitationPageParams>();

    const { enqueueSnackbar } = useSnackbar();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const client = onlinePatientManagement.serviceStackClient;

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : '');
    };

    const invitationGuid = params?.guid;

    const [invitation, setInvitation] = React.useState<Dtos.Invitation | undefined>();

    const [, setPicfs] = React.useState<Dtos.Picf[] | undefined>();

    const [eoiSubmitted, setEoiSubmitted] = React.useState(false);
    
    const picfsDownloadLink = `/opms/picf/download/all/${invitation?.institutionCode}`;

    React.useEffect(() => {
        client
            .get(new Dtos.InvitationGetSingleByGuid({
                guid: invitationGuid
            }))
            .then(response => {
                setInvitation(response.invitation)
            })
            .catch((e) => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        Invitation is not valid. Please contact your system administrator.
                    </>,
                    { variant: 'critical' }
                );
            });
    }, [client, enqueueSnackbar, invitationGuid, setInvitation]);


    React.useEffect(() => {
        if (invitation) {
            client
                .get(new Dtos.GetPicfs({
                    instCode: invitation?.institutionCode
                }))
                .then(response => {
                    setPicfs(response.picfs)
                })
                .catch((e) => {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                Error
                            </AlertTitle>
                            There is a problem with the site PICFs. Please contact your system administrator.
                        </>,
                        { variant: 'critical' }
                    );
                });
        }
    }, [client, enqueueSnackbar, invitation, setPicfs]);

    return (
        <>
            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    className={classes.title}
                >
                    <strong>The OPTIMA <Link href="https://www.breastcancertrials.org.au/trials/optima/" color="secondary">Trial</Link></strong> <br />
                    BCT OPTIMA
                </Typography>
                <Grid
                    container
                    spacing={5}
                >
                    {
                        !!isMobile && <Grid
                            item
                            xs={12}
                            md={4}
                            style={{ paddingBottom: 0 }}
                        >
                            <AppBar
                                position="sticky"
                                className={classes.quickAccess}
                            >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown} color="white" />}
                                        aria-controls="panel0a-content"
                                        id="panel0a-header"
                                        className={classes.quickAccessSummary}
                                    >
                                        <Typography>Quick Access</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <ListItem component={Link} href={"#OptimaVideo"} className={classes.link}>
                                                <ListItemText primary="OPTIMA Information Video" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaPurpose"} className={classes.link}>
                                                <ListItemText primary="What is the Purpose of OPTIMA?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaToDo"} className={classes.link}>
                                                <ListItemText primary="What Do I Need to Do First?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaInvolve"} className={classes.link}>
                                                <ListItemText primary="What Does Taking Part in OPTIMA Involve?" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaPlan"} className={classes.link}>
                                                <ListItemText primary="OPTIMA Trial Plan" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaProcedures"} className={classes.link}>
                                                <ListItemText primary="OPTIMA Trial Procedures" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaNextSteps"} className={classes.link}>
                                                <ListItemText primary="Next Steps" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaEoi"} className={classes.link}>
                                                <ListItemText primary="Expression of Interest" />
                                            </ListItem>
                                            <ListItem component={Link} href={"#OptimaResources"} className={classes.link}>
                                                <ListItemText primary="Resources" />
                                            </ListItem>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </AppBar>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={12}
                        md={8}
                    >
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            This page is a summary of the OPTIMA clinical trial and what happens if you decide to take part. Take as much time as you need to decide about taking part. Talk to your family, whānau, friends, and/or family doctor about taking part. Feel free to ask any questions of the Study Doctor and site staff at any time.</Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            OPTIMA is a clinical trial looking at treating (<CustomToolTip title="An Oestrogen Receptor is a protein that may be present on certain cells to which oestrogen molecules can attach. The term “ER-positive” refers to tumour cells that contain the oestrogen-receptor protein. These cells are generally sensitive to hormone therapy." label="oestrogen (ER) positive" />) and <CustomToolTip title="HER2 stands for Human Epidermal Growth Factor Receptor 2. HER2-negative means that the breast cancer cells have normal levels of HER2 proteins." label="HER2-negative" /> breast cancer that:</Typography>
                        <List>
                            <ListItem>
                                <ListItemText>Has spread to the <CustomToolTip title="Lymph nodes are part of the body’s immune system and help fight infection. If breast cancer spreads, it usually goes to the nearby lymph nodes under the arm, and can sometimes spread to the lymph nodes near your collar bone or breast bone." label="lymph nodes">lymph nodes</CustomToolTip> within or surrounding the breast, OR</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>Is a larger size cancer without spread to the lymph nodes.</ListItemText>
                            </ListItem>
                        </List>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            To understand more about the OPTIMA trial and what is involved in taking part, click on the headings below to expand the information. There is also further information on the <Link href={"#OptimaResources"} className={classes.link}>Resources</Link> section of this page</Typography>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="OptimaVideo"
                        >
                            OPTIMA Information Video
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            You may like to take notes of any questions or concerns you have while watching the video or reading this page to ask your study doctor and study team.
                        </Typography>
                        <div className={classes.cardMediaContainer}>
                            <CardMedia
                                component="iframe"
                                src="https://www.youtube.com/embed/YmsKG-t67CQ"
                                className={classes.cardMedia}
                            />
                        </div>
                        <div className={classes.accordions}>
                            <CustomAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} id="OptimaPurpose">
                                <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header" className={classes.accordionSummary}>
                                    <Typography>What is the Purpose of OPTIMA?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography>The purpose of OPTIMA is to find out if find out if using a test called <CustomToolTip title="Prosigna is a test of gene activity in breast cancer. It can provide more information about a person’s risk of cancer coming back, and helps inform decisions about cancer treatments. Prosigna is one of several tests available that test gene activity. Please see this list of publications related to the assessment of Prosigna in different patient groups, if you would like further information." label='“Prosigna”'/> can help make safe and accurate decisions about whether or not chemotherapy treatment is needed.</Typography> }/>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography>In the past few years, new tests like Prosigna have been developed that look more deeply into each individual breast cancer. These tests may be able to pick out patients who need chemotherapy and those who would not benefit from chemotherapy. We need to do more research into these tests to find out if they can be used to find patients who don’t need chemotherapy but will still have the cancer treated effectively. Giving less treatment with the same or better results is called “de-escalation” and can optimise treatment to best suit each patient.</Typography>} />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemText primary={<Typography>There is some evidence to suggest that the Prosigna test is effective at predicting if patients with breast cancer which has spread to lymph nodes within or surrounding the breast or with larger cancers are likely to benefit from chemotherapy. However, we might find out in the future that some patients who did not receive chemotherapy in the OPTIMA study, might have benefited from it. This is why we are doing OPTIMA – we need better evidence about how well the Prosigna test works.</Typography>} />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} id="OptimaToDo">
                                <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header" className={classes.accordionSummary}>
                                    <Typography>What Do I Need to Do First?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={<Typography>To take part in the OPTIMA clinical trial, you will need to talk with a study doctor about the study, have all your questions answered, and sign the <CustomToolTip title=" Consent is a process where a person agrees to take part based on a clear understanding of the facts, the risks and benefits, and what it might mean for them in the future. In the case of a clinical trial, these facts, risks, benefits and future impacts are set out in the Participant Information Sheet and any related materials, such as the information on this webpage." label="Consent" />  section of the full Participant Information Sheet/Consent Form.</Typography>} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="If you don’t wish to take part in this study, you don’t have to. You will receive the best possible care whether or not you take part. If you decide to take part, you can stop at any time if you change your mind later." />
                                        </ListItem>
                                        <ListItem>
                                        <ListItemText>
                                                All trials must be registered on a publicly accessible database. Further information on OPTIMA can also be found on the <Link href="https://www.isrctn.com/ISRCTN42400492" target="_blank" rel="noopener" className={classes.link}>public registry</Link>.
                                        </ListItemText>
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} id="OptimaInvolve">
                                <CustomAccordionSummary aria-controls="panel3d-content" id="panel3d-header" className={classes.accordionSummary}>
                                    <Typography>What Does Taking Part in OPTIMA Involve?</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="If you decide to take part in OPTIMA you will be asked to sign a Consent Form after reviewing the study information, and before starting any study procedures." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText>A process called <CustomToolTip title="A method of preventing bias in research by putting clinical trial participants into particular treatment groups. Participants or study doctors don’t get to choose the treatment, it is decided by chance – like flipping a coin. Randomisation ensures each treatment group has a similar range and number of participants, so that any differences between treatment groups at the end of the trial can be attributed to the study treatments." label="randomisation"/> will be used to put participants into one of two possible groups (Group 1 [Standard Treatment] or Group 2 [Treatment determined by a test]). Neither you nor your Study Doctor can choose which group you will be in. The researchers will request a sample of your breast cancer tissue, which was removed at the time of your breast surgery to do a genomic test called “Prosigna”. The sample will be sent to the central laboratory in London, UK, for this test.</ListItemText> 
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <u>Group 1:</u> The results of the Prosigna test <u>will not</u> decide your treatment. You will receive the standard treatment of chemotherapy followed by hormone therapy.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <u>Group 2:</u> The results of the Prosigna test <u>will</u> decide your treatment. Depending on the result of the test, you will either start chemotherapy followed by hormone treatment OR receive hormone treatment without chemotherapy.
                                            </ListItemText>
                                        </ListItem>
                                        <div className={classes.accordions} style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '15px'}}>
                                            <Accordion id="HER2">
                                                <AccordionSummary aria-controls="panel10d-content" id="panel10d-header" expandIcon={<FontAwesomeIcon icon={faChevronDown}/>} className={classes.accordionSummary}>
                                                    <Typography>Randomisation</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <img
                                                        className={classes.listImage}
                                                        src={randomisationImage}
                                                        alt={"Randomisation"}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="If you go straight to hormone therapy you will know that you are in Group 2. The result of the Prosigna test was used on the breast cancer tissue and showed that you are unlikely to benefit from chemotherapy." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="If you have chemotherapy followed by hormone therapy you and your doctor will not know if you are in Group 1 or Group 2. You might be having chemotherapy as standard (Group 1) OR you might be having chemotherapy because the results of the Prosigna test showed that you would likely benefit from chemotherapy." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="You will complete questionnaires during the study. The questionnaires will help the researchers understand about how you are feeling and how your treatment affects your use of healthcare services. They will be completed:" />
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Before you start study treatment
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                3 months after you join the study
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                6 months after you join the study
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                12 months after you join the study
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem} style={{ paddingLeft: '4rem' }}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleRegular} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                24 months after you join the study
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText primary="The questionnaires will take approximately 30 minutes to complete." />
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <FontAwesomeIcon icon={faCircleSolid} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                During and after your treatment the study doctor will follow up with you to check on your general health <u>once a year for 10 years.</u> You may be asked to come to the hospital for this, or the study team can contact you by telephone or email instead. The hospitals involved in OPTIMA will have procedures in place to minimise the risk from COVID-19 and make sure patients receive the treatment they need.
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} id="OptimaPlan">
                                <CustomAccordionSummary aria-controls="panel4d-content" id="panel4d-header" className={classes.accordionSummary}>
                                    <Typography>OPTIMA Trial Plan</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={trialPlanImage}
                                                alt={"OPTIMA Trial Plan"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                            <CustomAccordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} id="OptimaProcedures">
                                <CustomAccordionSummary aria-controls="panel6d-content" id="panel6d-header" className={classes.accordionSummary}>
                                    <Typography>OPTIMA Trial Procedures</Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                    <List className={classes.listImage}>
                                        <ListItem className={classes.listImage}>
                                            <img
                                                className={classes.listImage}
                                                src={studyProceduresImage}
                                                alt={"OPTIMA Study Procedures"}
                                            />
                                        </ListItem>
                                    </List>
                                </CustomAccordionDetails>
                            </CustomAccordion>
                        </div>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                        >
                            Collection of Extra Information
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Australian participants will also be asked to consent to the collection of their Medicare and Pharmaceutical Benefits Scheme Information. This will help researchers compare how cost effective the treatments of the two study groups are in Australia.
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="OptimaNextSteps"
                        >
                            Next Steps
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please <Link href={picfsDownloadLink} target="_blank" rel="noopener" className={classes.link}>click here</Link> to review the full Participant Information Sheet/Consent Form to make sure you have all the information you need to make a decision.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please complete the form below if you are interested in more information about OPTIMA. You will be contacted by the study doctor or the study team at your hospital. They will answer any of your questions or concerns about OPTIMA, and help you understand the study procedures so you can decide if you want to take part.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            Please ask as many questions as you would like before agreeing to take part in the OPTIMA trial. You can also withdraw your consent at any time
                        </Typography>
                        <Typography
                            variant="h2"
                            className={classes.eoiTitle}
                            id="OptimaEoi"
                        >
                            I would like to speak to the study team about this study
                        </Typography>
                        {
                            !eoiSubmitted && <EoiForm setEoiSubmitted={setEoiSubmitted}/>
                        }
                        {
                            !!eoiSubmitted && <div className={classes.submitSuccess}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={1}
                                        xl={1}
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                    >
                                        <FontAwesomeIcon icon={faCheckCircle} className={classes.submitSuccessIcon} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={10}
                                        lg={11}
                                        xl={11}
                                    >
                                        <div className={classes.submitSuccessMessage}>
                                            <span>Thank you for completing <br /> the Expression of Interest form</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <Typography
                            variant="h2"
                            className={classes.subtitle}
                            id="OptimaResources"
                        >
                            Resources
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.breastcancertrials.org.au/home" target="_blank" rel="noopener" className={classes.link}>Breast Cancer Trials (BCT)</Link> is working with the research team based at University College London in the United Kingdom to conduct OPTIMA throughout Australia and New Zealand. OPTIMA is also being conducted in the UK, Norway and Sweden.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href={process.env.PUBLIC_URL + "/Documents/BCT 2202 OPTIMA Trial Summary Participant Information V2.pdf"} target="_blank" rel="noopener" className={classes.link}>OPTIMA Trial Summary Participant Information</Link> &ndash; click to download or print a summary of the OPTIMA trial
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.youtube.com/watch?app=desktop&v=_uPtwHbHfpk&list=PLfaKgjeYDhnV2oWOsnZ9fxtMDQN9KErdQ&index=2&ab_channel=BreastCancerTrials" target="_blank" rel="noopener" className={classes.link}>What is Tissue Banking?</Link> &ndash; <Link href={process.env.PUBLIC_URL + "/Documents/Tissue Banking.pdf"} target="_blank" rel="noopener" className={classes.link}>Tissue Banking</Link> is a common part of many clinical trials, including OPTIMA. It is storing biological samples (tumour, tissue, blood, etc.) in a special tissue bank for use in future research projects after the OPTIMA trial is finished. It is optional, meaning that you will be given a separate information and consent form about tissue banking in the OPTIMA study. You don’t have to agree to tissue banking if you don’t want to.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href={process.env.PUBLIC_URL + "/Documents/Genomic Tests.pdf"} target="_blank" rel="noopener" className={classes.link}>Genomic Tests</Link> &ndash; click to download some general information on genomic tests such as ProSigna
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.youtube.com/watch?v=cah8HS7XzG0&list=PLfaKgjeYDhnV2oWOsnZ9fxtMDQN9KErdQ&index=4" target="_blank" rel="noopener" className={classes.link}>What is De-escalation of Treatment?</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.breastcancertrials.org.au/luminal-b-breast-cancer/" target="_blank" rel="noopener" className={classes.link}>ER positive/HER2 negative (Luminal B) Breast Cancer</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.youtube.com/watch?v=9wk3PuugnYU&list=PLfaKgjeYDhnV2oWOsnZ9fxtMDQN9KErdQ&index=2" target="_blank" rel="noopener" className={classes.link}>What is informed consent</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.breastcancertrials.org.au/what-is-a-clinical-trial" target="_blank" rel="noopener" className={classes.link}>What is a Clinical Trial?</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                        >
                            <Link href="https://www.breastcancertrials.org.au/breast-cancer-resources/about-breast-cancer/" target="_blank" rel="noopener" className={classes.link}>What is Breast Cancer?</Link>
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            Ethics approval must be obtained for clinical trials involving human participation. PMCC HREC has approved the OPTIMA Trial HREC Reference HREC/92199/PMCC under <Link href="https://www.clinicaltrialsandresearch.vic.gov.au/national-mutual-acceptance" target="_blank" rel="noopener" className={classes.link}>NMA</Link> in Australia. NZ HDEC has approved the OPTIMA trial HDEC Reference 2024 FULL 18702 in New Zealand.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            The OPTIMA Trial and participant materials have been endorsed by the BCT Consumer Advisory Panel.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            Participating in OPTIMA will contribute to clinical research, impacting future clinical research, standard practice for treatment, and future breast cancer patients.
                        </Typography>
                        <Typography
                            variant="body1"
                            variantMapping={{
                                body1: 'p'
                            }}
                            className={classes.p}
                            style={{ fontSize: '0.9rem', fontStyle: 'italic' }}
                        >
                            BCT2202 OPTIMA Participant Information Webpage Version 2, 21<sup>st</sup> May 2024
                        </Typography>
                    </Grid>
                    {
                        !isMobile && <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            <AppBar
                                position="sticky"
                                className={classes.quickAccess}
                            >
                                <div
                                    className={classes.quickAccessHeader}
                                >
                                    Quick Access
                                </div>
                                <div
                                    className={classes.quickAccessBody}
                                >
                                    <List>
                                        <ListItem component={Link} href={"#OptimaVideo"} className={classes.link}>
                                            <ListItemText primary="OPTIMA Information Video" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaPurpose"} className={classes.link}>
                                            <ListItemText primary="What is the Purpose of OPTIMA?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaToDo"} className={classes.link}>
                                            <ListItemText primary="What Do I Need to Do First?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaInvolve"} className={classes.link}>
                                            <ListItemText primary="What Does Taking Part in OPTIMA Involve?" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaPlan"} className={classes.link}>
                                            <ListItemText primary="OPTIMA Trial Plan" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaProcedures"} className={classes.link}>
                                            <ListItemText primary="OPTIMA Trial Procedures" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaNextSteps"} className={classes.link}>
                                            <ListItemText primary="Next Steps" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaEoi"} className={classes.link}>
                                            <ListItemText primary="Expression of Interest" />
                                        </ListItem>
                                        <ListItem component={Link} href={"#OptimaResources"} className={classes.link}>
                                            <ListItemText primary="Resources" />
                                        </ListItem>
                                    </List>
                                </div>
                            </AppBar>
                        </Grid>
                    }
                </Grid>
            </div>
        </>  
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default InvitationPage;