
/*
 * -------------------------------------------------------------------------------------
 * - Imports - External
 * -------------------------------------------------------------------------------------
 */
import { AlertTitle } from "@material-ui/lab";
import { asyncDebounce, IFormState, IFormValidationError, IValidationError, OnlinePatientManagementContext, useAuthenticatedUser, useSnackbar, ValidationErrorType } from "@ngt/opms";
import pluralize from "pluralize";
import React, { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

/*
 * -------------------------------------------------------------------------------------
 * - Imports - Internal
 * -------------------------------------------------------------------------------------
 */
import * as Dtos from "../api/dtos";


/*
 * -------------------------------------------------------------------------------------
 * - Constants
 * -------------------------------------------------------------------------------------
 */
const SA_CONSENT_FILE_TYPE = 6;

/*
 * -------------------------------------------------------------------------------------
 * - Types
 * -------------------------------------------------------------------------------------
 */
export interface IUseSaConsentFormOptions {
    patient?: Dtos.Patient;
    consentForm?: Dtos.Consent;
    institution?: Dtos.Institution;
}

/*
 * -------------------------------------------------------------------------------------
 * - Hook
 * -------------------------------------------------------------------------------------
 */
const useSaConsentForm = <TForm extends Dtos.Consent>({
    patient,
    consentForm, 
    institution
}: IUseSaConsentFormOptions) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [user] = useAuthenticatedUser();
    const onlinePatientManagement = useContext(OnlinePatientManagementContext);

    const formInitial = useMemo(() => {
        const sourceDocuments = consentForm?.sourceDocuments ?? [] as Dtos.SourceDocument[];

        if (sourceDocuments.findIndex(x => x.fileTypeId === SA_CONSENT_FILE_TYPE) === -1) {
            sourceDocuments.push(
                {
                    parentFormId: consentForm?.id,
                    parentFormDefinitionId: Dtos.FormDefinitionType.Consent,
                    repeat: 1,
                    
                    fileTypeId: 6,
                    
                    enteredBy: user?.displayName,
                    enteredDate: undefined
                } as Dtos.SourceDocument
            )
        }

        return {
            ...consentForm,
            sourceDocuments: sourceDocuments
        } as Dtos.Consent;
    }, [consentForm, user]);


    const allowSubmit = useCallback(async ({ errors }: IFormState<TForm, IValidationError>) => {
        if (!errors) {
            return true;
        }

        return !Object
            .keys(errors)
            .some(key => 
                errors[key] && 
                errors[key].some(e => 
                    e.type != null && 
                    e.type >= ValidationErrorType.Normal
                )
            );
    }, []);


    const onValidate = useCallback(async (formState: IFormState<TForm, IValidationError>) => {
        let errors: Record<string, IFormValidationError[]> = {};

        // If submit button clicked, skip validation and return errors list
        if (formState.values.saveButtonClicked == true) {
            return errors;
        }

        // Get SA source document
        const sourceDocument = formState.values.sourceDocuments.find(x => x.fileTypeId === 6);
        
        // If source document does not exist, through error and discontinue validation
        if (!sourceDocument) {
            errors['sourceDocuments'] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-001',
                type: Dtos.ValidationErrorType.Critical,
            }] as IFormValidationError[];

            return errors;
        }
        
        const sourceDocumentIdx = formState.values.sourceDocuments.indexOf(sourceDocument!);
        
        if (!sourceDocument.name) {
            errors[`sourceDocuments[${sourceDocumentIdx}].name`] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-002',
                type: Dtos.ValidationErrorType.Critical,
            }] as IFormValidationError[];
        }

        if (!sourceDocument.fileTypeId) {
            errors[`sourceDocuments[${sourceDocumentIdx}].fileTypeId`] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-003',
                type: Dtos.ValidationErrorType.Critical,
            }] as IFormValidationError[];
        }

        if (!sourceDocument.file) {
            errors[`sourceDocuments[${sourceDocumentIdx}].file`] = [{
                message: 'This field is required.',
                detailedMessage: 'This field is required.',
                code: 'R-004',
                type: Dtos.ValidationErrorType.Critical,
            }] as IFormValidationError[];
        }

        return errors;

    }, [institution]);

    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);


    const onFormSubmitValidationFailure = useCallback(async ({ errors }: IFormState<TForm, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Error
                    </AlertTitle>
                    An error occurred while attempting to upload the SA consent document.
                </>,
                { variant: 'critical' }
            );

        } else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type != null && e.type >= ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SA Consent Not Uploaded
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and try again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);


    const onFormSubmitFailure = useCallback(async ({ }: IFormState<TForm, IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Error
                </AlertTitle>
                An error occurred while attempting to upload the SA consent Document.
            </>,
            { variant: 'critical' }
        );
    }, [enqueueSnackbar]);


    const handleSubmit = React.useCallback(async ({ values }: IFormState<TForm, IValidationError>) => {
        onlinePatientManagement.serviceStackClient
            .post(new Dtos.ConsentPostSaveWithCodes({
                form: { 
                    ...values,
                    saConsentStatus: Dtos.SaConsentStatusType.ReadyForProcessing
                },
                institutionCode: institution?.code, 
                patientStudyNumber: patient?.studyNumber, 
                eventDefinitionCode: "consent", 
                eventRepeat: 1, 
                formRepeat: 1, 
                createPatient: false,
                createEvent: false
            }))
            .then(() => {

                history.push('/');

                {
                    enqueueSnackbar(
                        <>
                            <AlertTitle>
                                SA Consent Form Uploaded
                            </AlertTitle>
                            The Service Australia consent form was uploaded successfully.
                        </>,
                        { variant: 'success' }
                    );

                }
            })
            .catch(() => {
                enqueueSnackbar(
                    <>
                        <AlertTitle>
                            Error
                        </AlertTitle>
                        An error occurred while attempting to upload the Service Australia consent form.
                    </>,
                    { variant: 'critical' }
                );
            });
    }, [enqueueSnackbar, history, institution, onlinePatientManagement, patient]);

    return {
        formInitial,
        handleSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate: debouncedValidate,
        allowSubmit
    }
}


export default useSaConsentForm;