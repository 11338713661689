/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use pre-registered patient columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution
} from '@ngt/opms';

import { Chip, Link, Tooltip, Typography } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useRandomisedColumns = (
    institutions: IInstitution[] | null,
    showSite: boolean,
    onReviewClick: (patient: Dtos.Patient) => void,
    onSaReviewClick: (patient: Dtos.Patient) => void,
    onSaUploadClick: (patient: Dtos.Patient) => void,
    canConsentAdminister: boolean,
    canUpdatePatient: boolean,
    canPathologyTestAdminister: boolean
): Column<Dtos.Patient>[] => {
    const getStageName = React.useCallback((patientStatus: number|undefined) => {
        switch (patientStatus) {
            case Dtos.PatientStateType.Randomised: return 'Baseline'
            case Dtos.PatientStateType.ThreeMonthFollowUp: return ''
            case Dtos.PatientStateType.SixMonthFollowUp: return '6 Month Follow Up'
            case Dtos.PatientStateType.TwelveMonthFollowUp: return '12 Month Follow Up'
            case Dtos.PatientStateType.TwentyFourMonthFollowUp: return '24 Month Follow Up'
            default: return ''
        }
    }, []);


    const getPatientTreatment = (treatmentId: any) => {
        if ((treatmentId === Dtos.TreatmentType.ChemotherapyFollowedByEndocrineTherapy)) {
            return "Chemo & Endocrine";
        }
        else if (((treatmentId === Dtos.TreatmentType.EndocrineTherapyAlone))) {
            return "Endocrine";
        }
        else {
            return "N/A";
        }
    };

    const columns = React.useMemo(() => {
        const c: (Column<Dtos.Patient> | boolean)[] = [
            {
                field: 'studyNumber',
                title: 'Study Number',
                width: 155
            },
            {
                field: 'initials',
                title: 'Initials',
                width: 110
            },
            showSite && {
                field: 'institutionCode',
                title: 'Site Code',
                render: rowData => (
                    <Chip
                        label={rowData.institutionCode}
                        color="secondary"
                        size="small"
                    />
                ),
                width: 125
            },
            showSite && {
                field: 'institutionId',
                title: 'Institution',
                render: rowData => (
                    institutions?.find(i => i.id === rowData.institutionId)?.name
                )
            },
            {
                field: 'enteredDate',
                title: 'Randomisation Date',
                render: rowData => (
                    rowData.enteredDate ? DateTime.fromISO(rowData.randomisationDate!).toFormat('dd/MM/yyyy') : null
                ),
                width: 110
            },
            {
                field: 'treatment',
                title: 'Treatment',
                render: rowData => (
                    getPatientTreatment(rowData.treatmentId)
                ),
                width: 110
            },
            {
                field: 'patientStateId',
                title: 'Stage',
                render: rowData => (
                    getStageName(rowData.patientStateId)
                ),
                width: 90
            },
            (canConsentAdminister || (canUpdatePatient && !canPathologyTestAdminister)) && {
                field: 'consentStatus',
                title: 'Consent Status',
                render: rowData => {
                    switch (rowData.consentForm?.consentStatus) {
                        case Dtos.ConsentStatusType.Complete: {
                            if (rowData.consentForm?.reviewedDate != null && canConsentAdminister) {

                                return <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to view the consent form history</Typography>}
                                    placement="right"
                                >
                                    <Link color="primary" onClick={(e) => { e.stopPropagation(); onReviewClick(rowData); }}>Complete</Link>
                                </Tooltip>;
                            }

                            return 'Complete';
                        }
                        case Dtos.ConsentStatusType.ReadyForReview: {
                            if (canConsentAdminister) {
                                return <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to confirm and delete the consent form</Typography>}
                                    placement="right"
                                >
                                    <Link color="secondary" onClick={(e) => { e.stopPropagation(); onReviewClick(rowData); }}>Ready for Review</Link>
                                </Tooltip>
                            } else {
                                return 'Ready for Review'
                            }
                        }
                        case Dtos.ConsentStatusType.InProgress: return 'In Progress';
                        case Dtos.ConsentStatusType.NewConsentFormRequired: {
                            if (canConsentAdminister) {
                                return <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to view the consent form history</Typography>}
                                    placement="right"
                                >
                                    <Link color="primary" onClick={(e) => { e.stopPropagation(); onReviewClick(rowData); }}>New Consent Form Required</Link>
                                </Tooltip>;
                            } else {
                                return 'New Consent Required';
                            }
                        }
                        default: return '';
                    }
                },
                width: 210
            },
            (canConsentAdminister || (canUpdatePatient && !canPathologyTestAdminister)) && {
                field: 'saConsentStatus',
                title: 'SA Consent',
                render: rowData => {
                    if (!rowData.consentForm?.servicesAustraliaRequired ||
                        rowData.consentForm?.consentStatus === Dtos.ConsentStatusType.InProgress ||
                        rowData.consentForm?.consentStatus === Dtos.ConsentStatusType.NewConsentFormRequired
                    ) {
                        return '';
                    }
                    
                    switch (rowData.consentForm?.saConsentStatus) {
                        case Dtos.SaConsentStatusType.Upload: {
                            return (
                                <Tooltip
                                    style={{ width: '100%' }}
                                    title={<Typography style={{ fontSize: '0.75rem' }}>Click here to upload the SA consent document</Typography>}
                                    placement="right"
                                >
                                    <Link color="secondary" onClick={(e) => { e.stopPropagation(); onSaUploadClick(rowData); }}>Upload</Link>
                                </Tooltip>
                            );
                        } 
                        case Dtos.SaConsentStatusType.ReadyForProcessing: {
                            if (canConsentAdminister) {
                                return ( 
                                    <Tooltip
                                        style={{ width: '100%' }}
                                        title={<Typography style={{ fontSize: '0.75rem' }}>Click here to process the SA consent document</Typography>}
                                        placement="right"
                                    >
                                        <Link color="secondary" onClick={(e) => { e.stopPropagation(); onSaReviewClick(rowData); }}>Ready for Processing</Link>
                                    </Tooltip>
                                );
                            } else {
                                return 'Ready for Processing';
                            }
                        }
                        case Dtos.SaConsentStatusType.Exported:
                        case Dtos.SaConsentStatusType.Processed: return 'Processed';
                        default: return '';
                    }
                },
                width: 210
            }
        ];

        return c.filter(column => !!column) as Column<Dtos.Patient>[];
    }, [showSite, institutions, getStageName, canConsentAdminister, canUpdatePatient, canPathologyTestAdminister, onReviewClick]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useRandomisedColumns;