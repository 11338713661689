
import { Button, Dialog, DialogActions, DialogContent, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { FieldGroup, Form, IFormLabel, Input, InstitutionsContext, IStaticGridCellProps, IStaticGridHeaderProps, OnlinePatientManagementContext, pascalToCameCasePropertyPath, Select, StaticGrid, StaticGridRow, SubmitButton, Text, useLookupsByFormDefinitionId } from "@ngt/opms";
import React, { FC, useContext, useMemo } from "react";

import useSaConsentForm from "src/hooks/useSaConsentForm";
import * as Dtos from '../../api/dtos';
import SourceDocumentFileUpload from "../SourceDocumentFileUpload";


const SA_CONSENT_FILE_TYPE = 6;

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        color: theme.palette.secondary.main,
        padding: theme.spacing(2.5, 3, 0),
        fontSize: '1.25rem !important',
        fontWeight: 500,
    },
    dialogContent: {
        padding: 0
    },
    dialogActions: {
        padding: `${theme.spacing(1, 0, 0)}`
    },
    historyDropDown: {
        padding: theme.spacing(2.5, 3, 0),
    },
}));

const existingLink = (value?: Dtos.IFileUpload) => {
    const file = value as Dtos.File;
    if (file != null)
    {
        if (file.extension == "pdf" || file.extension == ".pdf")
        {
            return `/document/${file?.id}`;
        }

    }
    return `/opms/file/view/${file?.id}`;
}

const existingDownloadLink = (value?: Dtos.IFileUpload) => {
    const file = value as Dtos.File;

    return `/opms/file/download/${file?.id}`;
}


const header: IStaticGridHeaderProps[] = [
    {
        columnName: 'name',
        content: 'Name',
    },
    {
        columnName: 'fileTypeId',
        content: 'Type',
    },
    {
        columnName: 'file',
        content: 'File',
    }
];

const columns: (index: number) => IStaticGridCellProps[] = (index) => ([
    {
        columnName: 'name',
        content: (
            <Input component={Text} />
        ),
        fieldName: `sourceDocuments[${index}].name`
    },
    {
        columnName: 'fileTypeId',
        content: (
            <Input 
                disabled
                component={Select}
                nullOption={false}
            >
                <MenuItem
                    key={"6"}
                    value={6}
                >
                    Services Australia Consent
                </MenuItem>
            </Input>
        ),
        fieldName: `sourceDocuments[${index}].fileTypeId`
    },
    {
        columnName: 'file',
        content: (
            <Input
                component={SourceDocumentFileUpload}
                existingLink={existingLink}
                existingDownloadLink={existingDownloadLink}
            />
        ),
        fieldName: `sourceDocuments[${index}].file`
    }
]);


const labels: IFormLabel[] = [
    {
        name: 'consentFormVersion',
        label: 'Version Number',
        detailedLabel: ''
    },
    {
        name: 'reviewedBy',
        label: 'Reviewed By',
        detailedLabel: ''
    },
    {
        name: 'reviewedDate',
        label: 'Date',
        detailedLabel: ''
    }
];


interface ISaConsentUploadDialogProps {
    onCancel?: () => void;
    patient?: Dtos.Patient;
    consentForm?: Dtos.Consent;
    open: boolean;
    formDefinitionId: number;
}

const SaConsentUploadDialog: FC<ISaConsentUploadDialogProps> = ({
    open,
    onCancel,
    patient,
    consentForm,
    formDefinitionId
}) => {
    const classes = useStyles();

    const { institutions } = useContext(InstitutionsContext);

    const institution = useMemo(() => {
        return institutions?.find(i => i.id === patient?.institutionId);
    }, [institutions, patient]);

    const [ lookups ] = useLookupsByFormDefinitionId(formDefinitionId, true);

    const processedLookups = React.useMemo(() => {
        return lookups?.map(lookup => ({ ...lookup, propertyName: pascalToCameCasePropertyPath(lookup.propertyName) }))
    }, [lookups]);

    // const {
    //     formInitial,
    //     handleSubmit,
    //     onFormSubmitFailure,
    //     onFormSubmitValidationFailure,
    //     validate,
    //     allowSubmit
    // } = useConsentForm({ consentForm: consentForm, institution: institution, onCancel: onCancel, });

    const {
        formInitial,
        allowSubmit,
        handleSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useSaConsentForm({ 
        patient, 
        institution,
        consentForm
    });

    return (
        <>
            {
                (patient || (patient && (patient as Dtos.Patient).institutionCode)) && <Dialog 
                    fullWidth
                    disableBackdropClick
                    open={open}
                    scroll="body"
                    maxWidth="lg"
                    onClose={onCancel}
                >
                    <Form
                        initialValues={formInitial}
                        onValidate={validate}
                        validateOn="onChange"
                        allowSubmit={allowSubmit}
                        fieldErrors={'default'}
                        onSubmit={handleSubmit}
                        labels={labels}
                        lookups={processedLookups ?? []}
                        onSubmitFailed={onFormSubmitFailure}
                        onSubmitValidationFailed={onFormSubmitValidationFailure}
                    >
                        <DialogContent className={classes.dialogContent}>
                            <Typography
                                variant="h4"
                                color="primary"
                                className={classes.dialogTitle}
                                style={{ marginBottom: '16px' }}
                            >
                                Confirm Service Australia Document for {patient?.studyNumber}
                            </Typography>

                            <FieldGroup>
                                <StaticGrid 
                                    headers={header}
                                >
                                    <StaticGridRow cells={columns(formInitial?.sourceDocuments?.findIndex(x => x.fileTypeId === SA_CONSENT_FILE_TYPE) ?? 0)} />
                                </StaticGrid>
                            </FieldGroup>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button onClick={onCancel} color="secondary" disabled={false}>
                                Cancel
                            </Button>
                            <SubmitButton
                                color="primary"
                            >
                                Upload Document
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                </Dialog>
            }
        </>
    );
}


const SaConsentUploadDialogWrapper: FC<Omit<ISaConsentUploadDialogProps, "formDefinitionId">> = (props) => {
    const { formMetadata } = useContext(OnlinePatientManagementContext);

    const formDefinition = useMemo(() => {
        return formMetadata?.find(x => x.formDefinitionCode === 'consent');
    }, [formMetadata]);

    if (!formDefinition?.formDefinitionId) {
        return null;
    }

    return (
        <SaConsentUploadDialog 
            {...props}
            formDefinitionId={formDefinition.formDefinitionId}
        />
    );
}


export default SaConsentUploadDialogWrapper;